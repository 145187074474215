import type { LanguageInfo, TabData, TabHash } from '@/reports/types';
import { Hook, render } from '@/tools/markdown/renderer';
import * as Hooks from '@/reports/methods/hooks';
import { template } from '@/tools/markdown/fermium-md';
import ISO6391 from 'iso-639-1';
import { groupBy } from 'lodash/fp';
import { ReportEntry } from '@/models';
import { useAuthStore } from '@/auth/store';

export type ReportEntriesToMonthlyReportEntries = (res: ReportEntry[]) => MonthlyReportEntries;
export type MonthlyReportEntries = Record<string, ReportEntry[]>;

export namespace DateFunctions {
    export const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    export const getMonthString = (date: Date): string => {
        return months[date.getMonth()];
    };

    export const getDateString = (date: Date): string => {
        return `${DateFunctions.getMonthString(date)} ${date.getFullYear()}`;
    };

    export const monthStringToNumber = (month: string): number => months.indexOf(month);

    export const getLastYear = (dates: Date[]) => (dates.length > 0 ? dates[0] : new Date()).getFullYear();

    export const getLastMonth = (dates: Date[]) =>
        DateFunctions.getMonthString(dates.length > 0 ? dates[0] : new Date());

    export const getShortMonthName = (month: string): string => month.substring(0, 3);

    export const getMonthsInYear =
        (year: number) =>
        (dates: Date[]): Date[] =>
            dates.filter((d) => d.getFullYear() === year);
}

export namespace ReportEntryFunctions {
    const reduceDatePrecision = (date: Date): string => new Date(date.getFullYear(), date.getMonth(), 10).toISOString(); // Two Date obj could be different!

    export const groupByMonth: ReportEntriesToMonthlyReportEntries = groupBy(({ created_time }) =>
        reduceDatePrecision(created_time as Date)
    );

    export const parse = (r: ReportEntry): ReportEntry => {
        return {
            ...r,
            created_time: new Date(r.created_time),
            public_date: new Date(r.public_date),
            updated_time: new Date(r.updated_time),
        };
    };

    export const sortByMonth = (entries: MonthlyReportEntries) => {
        return Object.keys(entries)
            .sort()
            .reverse()
            .reduce((acc: MonthlyReportEntries, key: string) => {
                acc[key] = entries[key];
                return acc;
            }, {});
    };

    export const verboseISOString = (entries: MonthlyReportEntries) => {
        return Object.keys(entries).reduce((acc: MonthlyReportEntries, key: string) => {
            const date = new Date(key);
            acc[DateFunctions.getDateString(date)] = entries[key];
            return acc;
        }, {});
    };
}

export namespace TabFunctions {
    export const parseMdToTabs =
        (fm_id: string) =>
        (markdown: string): TabData[] => {
            const authStore = useAuthStore();
            const baseURL = authStore.downloadUrl(fm_id);
            const tabs: TabData[] = [];

            const hooks: Record<string, Hook> = {
                cve: Hooks.removeElement,
                overview: Hooks.TabHookFactory(tabs, 'overview', 'Overview', '#'),
                'technical-details': Hooks.TabHookFactory(
                    tabs,
                    'technical-details',
                    'Technical Details',
                    '#technical-details'
                ),
                exploitation: Hooks.TabHookFactory(tabs, 'exploitation', 'Exploitation', '#exploitation'),
                mitigations: Hooks.TabHookFactory(tabs, 'mitigations', 'Mitigations', '#mitigations'),
            };

            render(markdown, template, { baseURL, hooks });
            return tabs;
        };

    export const selectTab = (hash: TabHash) => (tabs: TabData[]) => tabs.filter((t) => t.href === hash);
}

export namespace LangFunctions {
    export const LANG_CODES = ['ko', 'en'];
    export const getLanguageInfo = (c: string): LanguageInfo => ({ name: ISO6391.getName(c), code: c });
    export const getLanguageList = (languageCodes: string[]): LanguageInfo[] => languageCodes.map(getLanguageInfo);
}
