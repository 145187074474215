import { computed, Ref, ref, watch } from 'vue';
import { Attachment } from '@/models';
import { useAttachmentDelete } from '@/internal/composables/useAttachmentDelete';
import { API_ENDPOINT, ATTACHMENT_TYPE } from '@/types';
import { attachmentTypeToString } from '@/internal/methods/functions';
import { useSwrvAsync } from '@/composables/useSwrvAsync';
import { useAttachmentUpload } from '@/internal/composables/useAttachmentUpload';
import { useToggle } from '@/composables/useToggle';

export const useAttachments =
    (type: Ref<ATTACHMENT_TYPE>) => (slice: [number, number?]) => async (fm_id: Ref<string>) => {
        const params = computed(() => ({
            fm_id: fm_id.value,
        }));

        const { data, mutate: fetchAttachments } = await useSwrvAsync<Attachment[]>(API_ENDPOINT.INTERNAL_ATTACHMENT)()(
            params
        );

        const {
            creating,
            model: createModel,
            state: createState,
            error: createError,
            onCreateHandler,
        } = useAttachmentUpload(fm_id.value)(type.value);

        const { toggle, toggled } = useToggle();

        const attachments = computed(() => data.value.filter((a: Attachment) => a.type === type.value).slice(...slice));

        const attachmentsInfo = computed(() =>
            attachments.value.map((attachment: Attachment) => {
                const deleteAttachment = useAttachmentDelete(fm_id.value)(type.value)(ref(attachment));

                const { toggle, toggled } = useToggle();

                watch(deleteAttachment.state, () => {
                    if (deleteAttachment.state.value === 'success') {
                        fetchAttachments();
                    }
                });

                return {
                    ...deleteAttachment,
                    toggle,
                    toggled,
                    attachment,
                };
            })
        );

        const error = computed(() => ({
            ...createError.value,
        }));

        const inputId = computed(() => `internal-attachment-input-${attachmentTypeToString(type.value)}`);

        watch([createState], () => {
            if ([createState.value].includes('success')) {
                fetchAttachments();
            }
        });

        watch(error, () => {
            if (error.value) {
                toggle(true);
            }
        });

        return {
            attachments: attachmentsInfo,
            model: createModel,
            creating: creating,
            error,
            inputId,
            toggled,
            toggle,
            onCreateHandler,
        };
    };
