import { Ref, computed } from 'vue';

import { Dashboard } from '@/models';
import { DateFunctions } from '@/reports/methods/functions';
import { ChartData } from 'chart.js';

interface Vendor {
    vendor: string;
    total: number;
    data: number[];
}

export const useStatusChartData = (data: Ref<Dashboard>) => {
    const OTHER_THRESHOLD = 3;

    const CHART_COLOR = ['#D2D890', '#86e09a', '#A6C2ED'];

    const CHART_FALLBACK_COLOR = '#4A5C79';

    const sum = (x: number[]) => x.reduce((acc, value) => acc + value, 0);

    const transpose = (m: number[][]) => (m.length ? m[0].map((x, i) => m.map((x) => x[i])) : [[]]);

    const today = new Date();

    const toMonthYearString = (d: Date) => `${DateFunctions.getMonthString(d)} ${d.getFullYear()}`;

    const targetMonths = [...Array(6).keys()]
        .reverse()
        .map((x) => new Date(today.getFullYear(), today.getMonth() - x, 15))
        .map(toMonthYearString);

    const mockData = Object.fromEntries(targetMonths.map((d) => [d, 0]));

    const toDateEntry = ([date, amount]: [string, number]): [string, number] => [
        toMonthYearString(new Date(`${date}-15`)),
        amount,
    ];

    const filterDateInRange = ([date]: [string, number]) => targetMonths.includes(date);

    const sortVendorByTotal = (a: Vendor, b: Vendor): number => (a.total < b.total ? 1 : -1);

    const toVendor = ([vendor, stats]: [string, Record<string, number>]): Vendor => {
        const dataEntries: [string, number][] = Object.entries(stats).map(toDateEntry).filter(filterDateInRange);
        const dataMerged: Record<string, number> = { ...mockData, ...Object.fromEntries(dataEntries) };
        const data: number[] = targetMonths.map((d) => dataMerged[d]);

        return {
            vendor: vendor,
            total: sum(data),
            data,
        };
    };

    const mergeVendors =
        (name: string = 'Others') =>
        (vendors: Vendor[]): Vendor => {
            const data = transpose(vendors.map((v) => v.data)).map((r) => sum(r));
            return {
                vendor: name,
                total: sum(data),
                data,
            };
        };

    const vendors = Object.entries(data.value.vendors_by_month).map(toVendor).sort(sortVendorByTotal);

    const vendorsCompressed = [
        ...vendors.slice(0, OTHER_THRESHOLD),
        ...[mergeVendors()(vendors.slice(OTHER_THRESHOLD))],
    ];

    const chartData = computed<ChartData>(() => ({
        datasets: vendorsCompressed
            .map((v, i) => ({
                label: v.vendor,
                backgroundColor: i < CHART_COLOR.length ? CHART_COLOR[i] : CHART_FALLBACK_COLOR,
                data: v.data,
                barThickness: 40,
            }))
            .reverse(),
        labels: targetMonths,
    }));

    return { chartData };
};
