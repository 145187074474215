import { RouteRecordRaw } from 'vue-router';
import AdminIndex from '@/admin/AdminIndex.vue';
import AdminCompanyView from '@/admin/views/AdminCompanyView.vue';
import AdminCompanyUserView from '@/admin/views/AdminCompanyUserView.vue';
import AdminCompanyUsersView from '@/admin/views/AdminCompanyUsersView.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/admin',
        name: 'admin',
        component: AdminIndex,
        meta: {
            title: 'Admin',
        },
        children: [
            {
                path: 'company',
                component: AdminCompanyView,
                name: 'admin-company',
            },
            {
                path: 'user',
                component: AdminCompanyUsersView,
                name: 'admin-users',
            },
            {
                path: 'user/new',
                component: AdminCompanyUserView,
                name: 'admin-user-create',
                props: { id: '' },
            },
            {
                path: 'user/:id',
                component: AdminCompanyUserView,
                name: 'admin-user',
                props: true,
            },
        ],
    },
];

export default routes;
