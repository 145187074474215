import type { AxiosInstance } from 'axios';

import axios from 'axios';
import Auth from './auth';

export class API {
    public http: AxiosInstance;
    public auth: Auth;

    constructor(http: AxiosInstance) {
        this.http = http;
        this.auth = new Auth(http);
    }
}

export const Api = new API(
    axios.create({
        baseURL: process.env.VUE_APP_BACKEND_URL,
    })
);
