import { defineStore } from 'pinia';

export const useFetchErrorStore = defineStore('fetch-error', {
    state: () => ({
        isError: false,
        code: '',
        message: '',
    }),
    getters: {},
    actions: {
        reset() {
            this.$patch({
                isError: false,
                code: '',
                message: '',
            });
        },
        set(code: string, message: string) {
            this.$patch({
                isError: true,
                code: code,
                message: message,
            });
        },
    },
});
