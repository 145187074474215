import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash/fp';

export const useInputStoreFactory =
    <M, E>(id: string) =>
    (model: M) =>
    (error: E) => {
        return {
            useStore: defineStore(id, {
                state: () =>
                    ({
                        model: cloneDeep(model),
                        error: cloneDeep(error),
                    } as { model: M; error: E }),
                getters: {},
                actions: {},
            }),
        };
    };
