import type { Ref } from 'vue';
import { SwrvResponse, useSwrv } from '@/composables/useSwrv';
import { API_ENDPOINT } from '@/types';

export const useSwrvAsync =
    <T>(endpoint: API_ENDPOINT) =>
    (id?: Ref<string>) =>
    (params?: Ref<Record<string, string>>) => {
        const isRefAvailable = <R>(r: Ref<R | undefined>): r is Ref<R> => r.value !== undefined;

        return new Promise<SwrvResponse>((resolve, reject) => {
            const { data, error, isValidating, state, mutate } = useSwrv<T>(endpoint)(id)(params);

            mutate().then(() => {
                if (isRefAvailable<T>(data)) {
                    resolve({ data, error, isValidating, state, mutate });
                } else {
                    reject(error.value);
                }
            });
        });
    };
