import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { debounce } from 'lodash/fp';

export const useSearchTerm = (onSearchTermChange?: () => void) => {
    const route = useRoute();

    const searchTerm = ref<string>((route.query.search ?? '').toString());
    const debouncedSearchTerm = ref<string>(searchTerm.value);
    const isSearchTermDebouncing = computed(() => searchTerm.value !== debouncedSearchTerm.value);

    watch(
        searchTerm,
        debounce(500)(() => {
            debouncedSearchTerm.value = searchTerm.value;

            if (onSearchTermChange) {
                onSearchTermChange();
            }
        }),
        { immediate: true }
    );

    return { searchTerm, debouncedSearchTerm, isSearchTermDebouncing };
};
