import type { RouteRecordRaw } from 'vue-router';
import ReportsIndex from '@/reports/ReportsIndex.vue';
import ReportsListView from '@/reports/views/ReportsListView.vue';
import ReportDetailView from '@/reports/views/ReportDetailView.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/reports',
        name: 'reports',
        component: ReportsIndex,
        meta: {
            title: 'Report',
        },
        children: [
            {
                path: '',
                name: 'reports-list',
                component: ReportsListView,
            },
            {
                path: ':id',
                name: 'report',
                component: ReportDetailView,
                props: true,
            },
            {
                path: ':id/:lang',
                name: 'report-lang',
                component: ReportDetailView,
                props: true,
            },
        ],
    },
];

export default routes;
