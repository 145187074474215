import { IMPACT_TYPE, IMPACT_TYPE_INDEX } from '@/types';
import { Dashboard } from '@/models';
import i18n from '@/plugins/i18n';

const { t } = i18n.global;

export const numberToSignedString = (number: Number): string => (number < 0 ? '' : '+') + number;

export const getDaysDiffFromToday = (date: Date): number =>
    Math.floor((new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24));

export const tierToString = (tier: number): string => {
    if (tier === 0) {
        return 'Basic Plan';
    } else if (tier === 1) {
        return 'Elite Plan';
    } else {
        return '';
    }
};

export const impactToString = (impact: IMPACT_TYPE_INDEX): string => IMPACT_TYPE[impact];

export const dashboardDataToImpactsChartData = (data: Dashboard) => ({
    labels: (Object.keys(data.impacts) as (keyof Dashboard['impacts'])[]).map(impactToString),
    datasets: [
        {
            data: Object.values(data.impacts),
            backgroundColor: ['#FFFF5C', '#A6C2ED', '#4A5C79'],
        },
    ],
});

export const dashboardDataToMetricCardData = (data: Dashboard) => [
    {
        title: t('dashboard.dashboard.total_reports'),
        amount: data.reports,
        delta: data.reports_month,
        tagOptions: { color: 'yellow', outline: false },
    },
    {
        title: t('dashboard.dashboard.exploits'),
        amount: data.exploits,
        tagOptions: { color: 'red', outline: false },
    },
    {
        title: t('dashboard.dashboard.vendors'),
        amount: Object.keys(data.vendors).length,
        tagOptions: { color: 'blue', outline: false },
    },
];
