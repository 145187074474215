import type { Router, RouteRecordRaw } from 'vue-router';
import { createWebHistory, createRouter } from 'vue-router';

import reportListRoutes from '@/reports/routes';
import dashboardRoutes from '@/dashboard/routes';
import profileRoutes from '@/profile/routes';
import internalRoutes from '@/internal/routes';
import authRoutes from '@/auth/routes';
import adminRoutes from '@/admin/routes';

import { useAuthStore } from '@/auth/store';
import { useFetchErrorStore } from '@/store/fetchError';
import storejs from 'storejs';
import { Api } from '@/plugins/api';

const fallbacks: RouteRecordRaw[] = [
    {
        path: '/:catchAll(.*)',
        redirect: '/',
    },
];

const routes: RouteRecordRaw[][] = [
    reportListRoutes,
    dashboardRoutes,
    profileRoutes,
    internalRoutes,
    authRoutes,
    adminRoutes,
    fallbacks,
];

const router: Router = createRouter({
    history: createWebHistory(),
    routes: routes.reduce((c, el) => c.concat(el), []),
});

router.beforeEach(async (to, from, next) => {
    document.title = `${to.meta.title} – Fermium`;

    const publicPages = ['login', 'password-reset', 'password-reset-token', 'activate'];
    const authRequired = !publicPages.includes(to.name?.toString() ?? '');

    const authStore = useAuthStore();

    const { token } = storejs.get('token') || { token: null, id: null };

    if (!authStore.data.login.user || token !== authStore.data.login.token) {
        if (!token) {
            Api.auth.logout();
        } else {
            Api.auth.setHeader(token);
            await authStore.refresh();
        }
    }

    if (authStore.httpError?.response?.status === 401) {
        Api.auth.logout();
    }

    if (authRequired && !authStore.data.login.user) {
        authStore.data.redirectTo = to.fullPath;
        next({ name: 'login' });
    } else {
        next();
    }
});

router.afterEach(() => {
    const fetchErrorStore = useFetchErrorStore();
    fetchErrorStore.reset();
});

export default router;
