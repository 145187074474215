import { createI18n } from 'vue-i18n';
import { AxiosInstance } from 'axios';
import storejs from 'storejs';
import ko from './ko.json';
import en from './en.json';
import { Api } from '@/plugins/api';

export type I18nMessageSchema = typeof ko;

const FALLBACK_LOCALE = 'en';

export const setAcceptLanguageHeader = (http: AxiosInstance) => (lang: string) => {
    http.defaults.headers.common['Accept-Language'] = lang;
};

export const setLanguageStore = (lang: string) => {
    storejs.set('lang', lang);
};

export const getLanguageStore = () => {
    if (!storejs.get('lang')) {
        setLanguageStore(navigator.language.substring(0, 2));
    }

    return storejs.get('lang');
};

export const updateLanguage = (lang: string) => {
    setLanguageStore(lang);
    setAcceptLanguageHeader(Api.http)(lang);
};

const language = getLanguageStore();

setAcceptLanguageHeader(Api.http)(language);

export default createI18n<[I18nMessageSchema], 'ko' | 'en'>({
    legacy: false,
    locale: language,
    fallbackLocale: FALLBACK_LOCALE,
    messages: {
        ko: ko,
        en: en,
    },
    datetimeFormats: {
        ko: {
            short: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            },
            long: {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            },
        },
        en: {
            short: {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            },
            long: {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                weekday: 'short',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            },
        },
    },
});
