import { defineStore } from 'pinia';
import { User } from '@/models';
import { Api } from '@/plugins/api';
import { PromiseError, PromiseState } from '@/store/types';
import { initState, onApiReject, onApiResolve } from '@/store/functions';

export interface AuthState {
    login: {
        dl: string;
        token: string;
        user?: User;
    };
    redirectTo: string;
}

export const useAuthStore = defineStore('auth', {
    state: () =>
        initState<AuthState>({
            login: {
                dl: '',
                token: '',
            },
            redirectTo: '/',
        }) as PromiseState<AuthState, PromiseError>,
    getters: {
        pdfUrl() {
            return (suffix: string) => `${Api.http.defaults.baseURL}/pdf/${this.data.login.dl}/${suffix}`;
        },
        downloadUrl() {
            return (suffix: string) => `${Api.http.defaults.baseURL}/dl/${this.data.login.dl}/${suffix}`;
        },
        isStaff() {
            return () => this.data.login.user?.is_staff ?? false;
        },
    },
    actions: {
        async login(username: string, password: string): Promise<void> {
            this.loading = true;
            return Api.auth
                .login({ username, password })
                .then(onApiResolve<AuthState>(this)('retrieve')('login'))
                .catch(onApiReject<AuthState>(this)('retrieve'));
        },

        async refresh(): Promise<void> {
            this.loading = true;
            this.$reset();
            return Api.auth
                .refresh()
                .then(onApiResolve<AuthState>(this)('retrieve')('login'))
                .catch(onApiReject<AuthState>(this)('retrieve'));
        },

        async resetError() {
            this.$patch({
                error: {
                    detail: [],
                },
            });
        },
    },
});
