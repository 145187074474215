<script lang="ts" setup></script>

<template>
    <div class="loader">
        <span class="spinner"></span>
    </div>
</template>

<style lang="scss" scoped>
@import '~@/styles/_variables.scss';
@import '~@/styles/_mixins.scss';

.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.spinner {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border: 4px solid $text-color-primary;
    border-bottom-color: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
