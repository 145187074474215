import gsap from 'gsap';

export const FadeFactory = () => ({
    beforeEnter: (el: HTMLElement) => {
        gsap.set(el, {
            opacity: 0,
        });
    },

    enter: (el: HTMLElement, done: gsap.Callback) => {
        gsap.to(el, {
            opacity: 1,
            onComplete: done,
        });
    },

    leave: (el: HTMLElement, done: gsap.Callback) => {
        gsap.to(el, {
            opacity: 0,
            onComplete: done,
        });
    },
});

export const UnfoldFadeFactory = (marginTo: string, marginFrom: string = '0') => ({
    beforeEnter: (el: HTMLElement) => {
        gsap.set(el, {
            opacity: 0,
            height: 0,
            margin: marginFrom,
            padding: 0,
        });
    },

    enter: (el: HTMLElement, done: gsap.Callback) => {
        gsap.to(el, {
            opacity: 1,
            onComplete: done,
            delay: Number(el.dataset.index as string) * 0.05,
            height: 'auto',
            ease: 'power2.in',
            margin: marginTo,
            padding: '',
        });
    },

    leave: (el: HTMLElement, done: gsap.Callback) => {
        gsap.to(el, {
            opacity: 0,
            onComplete: done,
            delay: Number(el.dataset.index as string) * 0.05,
            height: 0,
            ease: 'power2.out',
            margin: marginFrom,
            padding: 0,
        });
    },
});

export const DateSelectorSlideFactory =
    (amount: number = 50) =>
    (direction: 'left' | 'right') => ({
        beforeEnter: (el: HTMLElement) => {
            gsap.set(el, {
                opacity: 0,
                x: direction === 'left' ? amount : -amount,
            });
        },

        enter: (el: HTMLElement, done: gsap.Callback) => {
            gsap.to(el, {
                duration: 0.25,
                opacity: 1,
                x: 0,
                onComplete: done,
                ease: 'ease.in',
            });
        },

        leave: (el: HTMLElement, done: gsap.Callback) => {
            gsap.to(el, {
                duration: 0.25,
                opacity: 0,
                x: 0,
                onComplete: done,
                ease: 'ease.out',
            });
        },
    });
