// Note: It's roughly converted into ts from legacy renderer.js with a lot of 'any'.

import type { LanguageFn } from 'highlight.js';

import { Remarkable } from 'remarkable';
import { linkify } from 'remarkable/linkify';

import hljs from 'highlight.js/lib/core';

import cpp from 'highlight.js/lib/languages/cpp';
import diff from 'highlight.js/lib/languages/diff';
import javascript from 'highlight.js/lib/languages/javascript';
import plaintext from 'highlight.js/lib/languages/plaintext';
import python from 'highlight.js/lib/languages/python';
import x86asm from 'highlight.js/lib/languages/x86asm';
import html from 'highlight.js/lib/languages/xml';

export interface HookOptions {
    children: Array<any>;
    siblings: Array<any>;
    match: Array<any>;
}

export type Hook = (el: Element, options?: HookOptions) => void | Element;

export interface RenderOptions {
    baseURL?: string;
    hooks?: Record<string, Hook>;
    prehook?: Hook;
    posthook?: Hook;
    urlhook?: Hook;
    renderPass?: boolean;
    sourceLines?: boolean;
    numLines?: number;
}

const languages: { name: string; fn: LanguageFn }[] = [
    { name: 'cpp', fn: cpp },
    { name: 'diff', fn: diff },
    { name: 'javascript', fn: javascript },
    { name: 'plaintext', fn: plaintext },
    { name: 'python', fn: python },
    { name: 'x86asm', fn: x86asm },
    { name: 'html', fn: html },
];

languages.forEach((l) => hljs.registerLanguage(l.name, l.fn));

const md = new Remarkable({
    html: true,
    typographer: true,
    langPrefix: 'hljs language-',
    highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
            try {
                return hljs.highlight(lang, str).value;
            } catch (err) {
                // Ignore
            }
        }

        try {
            return hljs.highlightAuto(str).value;
        } catch (err) {
            // Ignore
        }

        return ''; // use external default escaping
    },
}).use(linkify);

function compareString(value: string, match: any) {
    value = value.trim();
    if (typeof match === 'string') {
        return value === match;
    } else if (match instanceof Array) {
        for (let i = 0; i < match.length; ++i) {
            if (compareString(value, match[i])) {
                return true;
            }
        }
        return false;
    } else {
        return value.match(match);
    }
}

function validateArray(first: Element, templates: any, options: any): any {
    let el = first;
    const elements = [];
    let result;
    if (templates instanceof Array) {
        for (let i = 0; i < templates.length; ++i) {
            if (el === null) {
                if (templates[i].optional) {
                    continue;
                }
                return { result: false, error: `Missing elements: ${templates[i].help}`, errorLine: options.numLines };
            }
            result = validate(el, templates[i], options);
            if (result.result === false) {
                if (templates[i].optional) {
                    continue;
                }
                return result;
            } else if (result.result === undefined) {
                --i;
            } else {
                elements.push(result.root);
            }
            el = result.next;
        }
    } else {
        while (el) {
            result = validate(el, templates, options);
            if (result.result === false) {
                if (templates.optional) {
                    continue;
                }
                break;
            } else if (result.result === true) {
                elements.push(result.root);
            }
            el = result.next;
        }
    }
    return { result: true, next: el, elements: elements };
}

function findSourceLine(el: Node | null) {
    while (el) {
        if (el.nodeType === Node.COMMENT_NODE && el.nodeValue && el.nodeValue.startsWith('line:')) {
            return parseInt(el.nodeValue.substring(5));
        }
        el = el.previousSibling || el.parentNode;
    }
}

function validate(root: any, template: any, options: any) {
    let children, match, result, siblings, sourceLine;

    if (options.sourceLines) {
        sourceLine = findSourceLine(root);
    }

    if (root.nodeValue && root.nodeType === Node.TEXT_NODE) {
        if (template.text === undefined) {
            if (root.nodeValue.trim() !== '') {
                return {
                    result: false,
                    error: `Expected node: ${template.tag}, got text: ${root.nodeValue} (${template.help})`,
                    errorLine: sourceLine,
                };
            }
            return { next: root.nextSibling };
        }
        return {
            root,
            result: compareString(root.nodeValue, template.text),
            next: root.nextSibling,
            error: `Expected text: ${template.text}, got: ${root.nodeValue} (${template.help})`,
            errorLine: sourceLine,
        };
    } else if (root.nodeType !== Node.ELEMENT_NODE) {
        return { next: root.nextSibling };
    }

    if (template.tag && !compareString(root.tagName, template.tag)) {
        return {
            result: false,
            error: `Expected tag: ${template.tag}, got: ${root.tagName} (${template.help})`,
            errorLine: sourceLine,
        };
    }
    if (template.textContent) {
        match = compareString(root.textContent, template.textContent);
        if (!match) {
            return {
                result: false,
                error: `Expected textContent: ${template.textContent}, got: ${root.textContent} (${template.help})`,
                errorLine: sourceLine,
            };
        }
    }

    if (template.children) {
        result = validateArray(root.firstChild, template.children, options);
        if (!result.result) {
            return result;
        }
        // lookup for extraneous nodes
        let el = result.next;
        while (el) {
            if (el.nodeType === Node.ELEMENT_NODE) {
                return { result: false, error: `Extraneous node: ${el.tagName}`, errorLine: sourceLine };
            } else if (el.nodeType === Node.TEXT_NODE && el.nodeValue.trim()) {
                return { result: false, error: `Extraneous text: ${el.nodeValue}`, errorLine: sourceLine };
            }
            el = el.nextSibling;
        }
        children = result.elements;
    }

    if (template.siblings) {
        result = validateArray(root.nextSibling, template.siblings, options);
        siblings = result.elements;
    } else {
        result = { result: true, next: root.nextSibling };
    }

    if (options.renderPass && template.id) {
        const cb = options.hooks[template.id];
        if (cb) root = cb(root, { children, siblings, match });
    }

    result.root = root;
    return result;
}

const tagAttrs: Record<string, string[]> = {
    A: ['href'],
    IMG: ['src'],
};

function forEachAttrUri(root: Element, cb: Function) {
    for (const tagName in tagAttrs) {
        for (const el of root.getElementsByTagName(tagName)) {
            for (const attrName of tagAttrs[tagName]) {
                const attr = el.getAttributeNode(attrName);
                if (attr && attr.specified) {
                    cb(attr);
                }
            }
        }
    }
}

function lineAnnotationPlugin(md: Remarkable, options: any) {
    md.core.ruler.push(
        'line-annotation',
        function (state) {
            const tokens = [];
            for (let i = 0; i < state.tokens.length; i++) {
                const token = state.tokens[i];

                if (token.lines) {
                    tokens.push({
                        type: 'htmltag',
                        content: `<!--line:${token.lines[0]}-->`,
                        level: token.level,
                    });
                }
                tokens.push(token);
            }
            state.tokens = tokens;
            return true;
        },
        options
    );
}

md.use(lineAnnotationPlugin);

export function render(markdown: string, template: any, options: RenderOptions) {
    // default options
    options = Object.assign(
        {
            baseURL: null,
            hooks: {},
            prehook: null,
            posthook: null,
            urlhook: null,
            renderPass: false,
            sourceLines: false,
            numLines: markdown.split(/\r\n|\r|\n/).length - 1,
        },
        options
    );

    if (options.sourceLines) {
        // @ts-ignore
        md.set({ sourceLines: options.sourceLines });
    }

    const root = document.createElement('div');
    root.innerHTML = md.render(markdown);

    if (options.prehook) options.prehook(root);

    if (options.baseURL) {
        forEachAttrUri(root, (attr: any) => {
            const value = attr.value;
            if (value.startsWith('.')) {
                if (options.baseURL) {
                    attr.value = `${options.baseURL}/${value}`;
                }
            }
        });
    }

    root.querySelectorAll('a').forEach((el) => {
        el.target = '_blank';
    });

    let result = validate(root, template, options);

    if (result.result && !options.renderPass) {
        options.renderPass = true;
        result = validate(root, template, options);
    }

    if (options.urlhook) {
        forEachAttrUri(root, (attr: any) => {
            options.urlhook && options.urlhook(attr);
        });
    }

    if (options.posthook) options.posthook(root);

    return Object.assign({}, result, { html: root.innerHTML });
}
