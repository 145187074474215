import { computed } from 'vue';
import { Filter, FilterGroup } from '@/reports/types';
import { useRoute } from 'vue-router';
import i18n from '@/plugins/i18n';

export const useReportsFilter = () => {
    const route = useRoute();
    const { t, locale } = i18n.global;

    const filterGroups = computed<FilterGroup[]>(() => [
        {
            title: t('reports.filters.report_type'),
            type: 'checkbox',
            filters: [
                {
                    key: { display: t('reports.filters.exploit'), value: 'has_exploit' },
                    selected: (route.query.has_exploit ?? '0') === '1',
                    values: {
                        selected: '1',
                        unselected: undefined,
                    },
                },
                {
                    key: { display: t('reports.filters.poc'), value: 'has_poc' },
                    selected: (route.query.has_poc ?? '0') === '1',
                    values: {
                        selected: '1',
                        unselected: undefined,
                    },
                },
            ],
        },
        {
            title: t('reports.filters.language'),
            type: 'radio',
            filters: [
                {
                    key: { display: t('languages.en'), value: 'language' },
                    selected: (route.query.language ?? locale) === 'en',
                    values: {
                        selected: 'en',
                        unselected: '',
                    },
                },
                {
                    key: { display: t('languages.ko'), value: 'language' },
                    selected: (route.query.language ?? locale) === 'ko',
                    values: {
                        selected: 'ko',
                        unselected: '',
                    },
                },
            ],
        },
    ]);

    const isFilterEnabled = computed<boolean>(
        () =>
            filterGroups.value
                .filter((g) => g.type === 'checkbox')
                .map((g) => g.filters)
                .map((fs: Filter[]) => fs.map((f) => f.selected))
                .flat()
                .filter((f) => f).length > 0
    );
    return { filterGroups, isFilterEnabled };
};
