import { PromiseError, PromiseState, APIResponse, APIRequestType, APIPromise } from '@/store/types';
import { Store } from 'pinia';
import { UnwrapRef } from 'vue';
import { AxiosError } from 'axios';

const requestTypeToKey = (x: APIRequestType): keyof APIPromise => {
    if (x === 'retrieve') {
        return 'loading';
    } else if (x === 'update') {
        return 'updating';
    } else if (x === 'create') {
        return 'creating';
    } else {
        return 'deleting';
    }
};

export const _initState =
    <T, E>(e: E) =>
    (x: T): PromiseState<T, E> => ({
        success: false,
        loading: false,
        updating: false,
        creating: false,
        deleting: false,
        error: e,
        data: x,
    });

export const initState = <T>(x: T): PromiseState<T, PromiseError> => _initState<T, PromiseError>({ detail: '' })(x);

export const _onApiResolve =
    <T, E>(store: Store<string, PromiseState<T, E>>) =>
    (type: APIRequestType) =>
    (key: keyof UnwrapRef<T>) =>
    (result: APIResponse<UnwrapRef<T>[typeof key]>) => {
        store.$patch({
            success: result.success,
            [requestTypeToKey(type)]: false,
            httpError: undefined,
        });

        store.data[key] = result.data;
    };

export const onApiResolve =
    <T>(store: Store<string, PromiseState<T, PromiseError>>) =>
    (type: APIRequestType) =>
    (key: keyof UnwrapRef<T>) =>
    (result: APIResponse<UnwrapRef<T>[typeof key]>) =>
        _onApiResolve<T, PromiseError>(store)(type)(key)(result);

export const _onApiReject =
    <T, E>(store: Store<string, PromiseState<T, E>>) =>
    (type: APIRequestType) =>
    (error: AxiosError<UnwrapRef<E>>) => {
        store.$patch({
            success: false,
            [requestTypeToKey(type)]: false,
            error: error.response?.data,
            httpError: error,
        });
        store.httpError = error;
    };

export const onApiReject =
    <T>(store: Store<string, PromiseState<T, PromiseError>>) =>
    (type: APIRequestType) =>
    (error: AxiosError<PromiseError>) =>
        _onApiReject(store)(type)(error);
