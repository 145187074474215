import type { Ref } from 'vue';
import useSWRV from 'swrv';
import { API_ENDPOINT } from '@/types';
import { Api } from '@/plugins/api';
import { SWRV_STATES, useSwrvState } from '@/composables/useSwrvState';
import { IResponse } from 'swrv/dist/types';

export interface SwrvResponse extends IResponse {
    state: Ref<SWRV_STATES>;
}

export const useSwrv =
    <T>(endpoint: API_ENDPOINT) =>
    (id?: Ref<string>) =>
    (params?: Ref<Record<string, string>>): SwrvResponse => {
        const { data, error, isValidating, mutate } = useSWRV<T>(
            () =>
                `${endpoint}${id ? '/' + id.value + '/' : ''}${params ? '/?' + new URLSearchParams(params.value) : ''}`,
            (key) => Api.http.get<T>(key).then((res) => res.data)
        );
        const state = useSwrvState(data, error, isValidating);
        return { data, error, isValidating, state, mutate };
    };
