import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from '@/router';
import i18n from '@/plugins/i18n';
import App from './App.vue';

import './assets/css/global.css';

const app = createApp(App);

app.use(createPinia());

app.use(i18n);

app.use(router);

app.mount('#app');
