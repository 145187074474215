import { API_ENDPOINT, ATTACHMENT_TYPE } from '@/types';
import { computed, Ref } from 'vue';
import { Attachment } from '@/models';
import { useApiMutate } from '@/composables/useApiMutate';
import { API_REQUEST_TYPE } from '@/composables/useApiRequest';

export const useAttachmentDelete =
    (fm_id: string) => (type: ATTACHMENT_TYPE) => (attachment: Ref<Attachment | undefined>) => {
        const { mutate, model, state, error } = useApiMutate<Attachment>({
            endpoint: API_ENDPOINT.INTERNAL_ATTACHMENT,
            type: API_REQUEST_TYPE.DELETE,
            id: attachment.value?.id,
        })({
            id: `attachment-delete-attachment-${fm_id}-${type}-${attachment.value?.id}`,
            model: attachment.value as Attachment,
            error: {},
        });

        const deleting = computed<boolean>(() => state.value === 'loading');

        return { deleting, model, mutate, state, error };
    };
