import { RouteRecordRaw } from 'vue-router';
import InternalIndex from '@/internal/InternalIndex.vue';
import InternalReportEditView from '@/internal/views/InternalReportEditView.vue';
import { useAuthStore } from '@/auth/store';

const routes: RouteRecordRaw[] = [
    {
        path: '/internal',
        name: 'internal',
        component: InternalIndex,
        meta: {
            title: 'Internal',
        },
        children: [
            {
                path: 'report',
                component: InternalReportEditView,
                name: 'report-new',
                props: { id: '' },
            },
            {
                path: 'report/:id/:lang',
                component: InternalReportEditView,
                name: 'report-edit',
                props: true,
            },
        ],
        beforeEnter(to, from, next) {
            const authStore = useAuthStore();
            if (authStore.isStaff()) {
                next();
            } else {
                return next({ name: 'dashboard' });
            }
        },
    },
];

export default routes;
