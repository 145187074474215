import type { Hook } from '@/tools/markdown/renderer';
import type { TabId, TabData, TabHash } from '@/reports/types';

const concatUntilH1 = (el: Element | null): string => {
    let result = '';
    while (el && el.tagName !== 'H1') {
        if (el.nodeType === Node.ELEMENT_NODE) {
            result += el.outerHTML;
        }
        el = el.nextElementSibling;
    }
    return result;
};

export const TabHookFactory = (results: TabData[], id: TabId, name: string, href: TabHash): Hook => {
    return (el) => {
        results.push({
            id: id,
            html: concatUntilH1(el.nextElementSibling),
            name: name,
            href: href,
        });
    };
};

export const removeElement: Hook = (el) => {
    if (el.parentNode) {
        el.parentNode.removeChild(el);
    }
};
