import { parse, ParsedData, ParsedMetadata } from '@/tools/markdown/fermium-md';
import { useAuthStore } from '@/auth/store';
import { ATTACHMENT_TYPE } from '@/types';

export const parseMdForEditor = (md: string) =>
    parse(md, {
        sourceLines: true,
        urlhook: (parsed: ParsedMetadata, attr: { value: string }) => {
            if (!parsed.fm_id) return;
            const value = attr.value;
            const authStore = useAuthStore();
            if (value.startsWith('.')) {
                attr.value = authStore.downloadUrl(parsed.fm_id) + '/' + attr.value;
            }
        },
    });

export const parsedToHtml = (result: ParsedData) => {
    if (!result.result && result.errorLine !== undefined) {
        return `<pre>Line ${result.errorLine + 1}: ${result.error}</pre>`;
    }
    return result.html;
};

export namespace VisibilityFunctions {
    export const VISIBILITY_CODES = [true, false];
    export const getVisibilityInfo = (c: boolean) => ({ name: c ? 'Public' : 'Private', code: c });
}

export const attachmentTypeToString = (type: ATTACHMENT_TYPE): string => {
    if (type === ATTACHMENT_TYPE.NONE) {
        return 'General File';
    } else if (type === ATTACHMENT_TYPE.EXP) {
        return 'Exploit';
    } else {
        return 'PoC';
    }
};
