import { Ref, ref } from 'vue';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { API_ENDPOINT } from '@/types';
import { Api } from '@/plugins/api';
import { Modifiable } from '@/models';

export enum API_REQUEST_TYPE {
    CREATE = 'post',
    UPDATE = 'put',
    DELETE = 'delete',
    PATCH = 'patch',
}

export type ApiState = 'idle' | 'loading' | 'success' | 'failed';

export interface ApiResponse<T, E> {
    data: Ref<T | undefined>;
    error: Ref<E | undefined>;
    rawError: Ref<AxiosError<E> | undefined>;
}

export interface ApiResponseEmpty {
    data: Ref<undefined>;
    error: Ref<undefined>;
    rawError: Ref<undefined>;
}

export interface ApiResponseSuccess<T> {
    data: Ref<T>;
    error: Ref<undefined>;
    rawError: Ref<undefined>;
}

export interface ApiResponseError<E> {
    data: Ref<undefined>;
    error: Ref<E | undefined>;
    rawError: Ref<AxiosError<E>>;
}

export const useApiRequest =
    <T, E>(endpoint: API_ENDPOINT) =>
    (type: API_REQUEST_TYPE) =>
    (id?: string) =>
    (data?: Ref<Record<string, string>> | Ref<Modifiable>) =>
    (options?: AxiosRequestConfig) => {
        const state = ref<ApiState>('idle');
        const result: Ref<T | undefined> = ref(undefined);
        const error: Ref<E | undefined> = ref(undefined);
        const rawError: Ref<AxiosError<E> | undefined> = ref(undefined);

        const isIdle = (r: ApiResponse<T, E>): r is ApiResponseEmpty => state.value === 'idle';
        const isLoading = (r: ApiResponse<T, E>): r is ApiResponseEmpty => state.value === 'loading';
        const isSuccess = (r: ApiResponse<T, E>): r is ApiResponseSuccess<T> => state.value === 'success';
        const isError = (r: ApiResponse<T, E>): r is ApiResponseError<E> => state.value === 'failed';

        const fetch = () => {
            state.value = 'loading';
            Api.http[type](`${endpoint}/${id ? id + '/' : ''}`, data?.value, options)
                .then((res) => {
                    state.value = 'success';
                    result.value = res.data;
                    error.value = undefined;
                })
                .catch((err: AxiosError<E>) => {
                    state.value = 'failed';

                    result.value = undefined;
                    rawError.value = err;

                    if ([400, 401, 403].includes(err.response?.status ?? 0)) {
                        error.value = err.response?.data;
                    } else {
                        error.value = undefined;
                    }
                });
        };

        return { fetch, result: { data: result, error, rawError }, state, isIdle, isLoading, isSuccess, isError };
    };
