// Note: It's roughly converted into ts from legacy fermium-md.js with a lot of 'any'.

import { render } from '@/tools/markdown/renderer';
import { IMPACT_TYPE, IMPACT_TYPE_INDEX } from '@/types';

const summaryTemplate = {
    tag: 'H1',
    textContent: /\[(FM-[0-9]+-[0-9]+)\] (.+)/,
    id: 'overview',
    siblings: [
        { tag: 'P', textContent: /^Date: (20[0-9]{2}\/[0-9]{2}\/[0-9]{2})$/, id: 'date', help: 'date' },
        { tag: 'P', textContent: /^CVE-[0-9]+-[0-9]+$/, optional: true, id: 'cve', help: 'cve' },
        { tag: 'H2', textContent: 'Description', id: 'description-header', help: 'description' },
        { tag: 'P', id: 'description', help: 'description' },
        { tag: 'H2', textContent: 'Impact', id: 'impact-header', help: 'impact' },
        {
            tag: 'H3',
            textContent: 'Type',
            id: 'type-header',
            siblings: [{ tag: 'UL', children: [{ tag: 'LI', id: 'type' }] }],
            help: 'type',
        },
        {
            tag: 'H3',
            textContent: 'Exploitability',
            id: 'exploitability',
            siblings: [{ tag: 'UL' }],
            help: 'exploitability summary',
        },
        {
            tag: 'H3',
            textContent: 'Affected Products',
            id: 'products-header',
            siblings: [
                {
                    tag: 'UL',
                    id: 'products',
                    children: [
                        {
                            tag: 'LI',
                            id: 'platforms',
                            children: [{ text: 'Platforms' }, { tag: 'UL' }],
                            help: 'affected platforms',
                        },
                        {
                            tag: 'LI',
                            id: 'versions',
                            optional: true,
                            children: [{ text: 'Versions' }, { tag: 'UL' }],
                            help: 'affected versions',
                        },
                    ],
                },
            ],
            help: 'affected products',
        },
        { tag: 'H2', textContent: 'Mitigation', id: 'mitigation-header', help: 'mitigation summary' },
        { tag: 'UL', id: 'mitigation', help: 'mitigation summary' },
        { tag: 'H2', textContent: 'Public information', id: 'public-info-header', help: 'public information' },
        { tag: 'UL', id: 'public-info', help: 'public information' },
    ],
};

const template = {
    id: 'root',
    children: [
        summaryTemplate,
        {
            tag: 'H1',
            id: 'technical-details',
            textContent: 'Technical Details',
            siblings: { tag: /^(?!H1).*$/ },
            help: 'technical details',
        },
        {
            tag: 'H1',
            id: 'exploitation',
            textContent: 'Exploitation',
            optional: true,
            siblings: [
                {
                    tag: 'H2',
                    textContent: 'Proof-of-concept (PoC)',
                    optional: true,
                    id: 'poc',
                    siblings: { tag: /^(?!H[12]).*$/ },
                    help: 'proof of concept',
                },
                {
                    tag: 'H2',
                    textContent: 'Exploit',
                    optional: true,
                    id: 'exploit',
                    siblings: { tag: /^(?!H[12]).*$/ },
                    help: 'exploit',
                },
            ],
            help: 'exploitation',
        },
        {
            tag: 'H1',
            id: 'mitigations',
            textContent: 'Mitigations',
            siblings: { tag: /^(?!H1).*$/ },
            help: 'mitigations section',
        },
    ],
};

export interface ParsedMetadata {
    fm_id: string;
    title: string;
    public_date: Date;
    description: string;
    cve: string;
    type: string;
    has_exploit: boolean;
    has_poc: boolean;
    type_header: string;
    exploitability?: Element;
    products_header: string;
    mitigation: Element | null;
    public_info_poc: 'Available' | 'Not Available' | string;
    public_info_exploit: 'Available' | 'Not Available' | string;
    public_info_exploit_element: Element | null;
    public_info_rest_element: Element | null;
    platforms: Element | null;
    versions: Element | null;
    impact_type: IMPACT_TYPE_INDEX;
    impact_type_str: string;
}

export interface ParsedData {
    error?: string;
    errorLine?: number;
    html: string;
    parsed: ParsedMetadata;
    result: boolean;
}

function parse(input: string, options: any): ParsedData {
    options = options || {};

    const parsed: ParsedMetadata = {
        mitigation: null,
        products_header: '',
        public_info_poc: 'Not Available',
        public_info_exploit: 'Not Available',
        public_info_exploit_element: null,
        public_info_rest_element: null,
        type_header: '',
        cve: '',
        description: '',
        fm_id: '',
        has_exploit: false,
        has_poc: false,
        public_date: new Date(),
        title: '',
        type: '',
        platforms: null,
        versions: null,
        impact_type: '-1',
        impact_type_str: IMPACT_TYPE['-1'],
    };

    const getFirstSiblingContent = (siblings: Array<Element>) => {
        return siblings && siblings.length > 0 ? siblings[0].textContent ?? '' : '';
    };

    const result = render(input, template, {
        ...options,
        hooks: {
            overview: function (el: Element, { match }: { match: Array<string> }) {
                parsed.fm_id = match[1];
                parsed.title = match[2];
            },
            date: function (el: Element, { match }: { match: Array<string> }) {
                parsed.public_date = new Date(match[1]);
            },
            description: function (el: Element) {
                parsed.description = el.textContent ? el.textContent.trim() : '';
            },
            cve: function (el: Element, { match }: { match: Array<string> }) {
                parsed.cve = match[0];
            },
            type: function (el: Element) {
                parsed.type = el.textContent ?? '';

                const impactTypeIndex: IMPACT_TYPE_INDEX =
                    (Object.keys(IMPACT_TYPE) as IMPACT_TYPE_INDEX[]).find((k) => IMPACT_TYPE[k] === parsed.type) ??
                    '-1';

                [parsed.impact_type, parsed.impact_type_str] = [impactTypeIndex, IMPACT_TYPE[impactTypeIndex]];
            },
            exploit: function () {
                parsed.has_exploit = true;
            },
            poc: function () {
                parsed.has_poc = true;
            },
            'type-header': function (el: Element, { siblings }: { siblings: Array<Element> }) {
                parsed.type_header = getFirstSiblingContent(siblings);
            },
            exploitability: function (el: Element, { siblings }: { siblings: Element[] }) {
                if (siblings.length > 0) {
                    parsed.exploitability = siblings[0];
                }
            },
            'products-header': function (el: Element, { siblings }: { siblings: Array<Element> }) {
                parsed.products_header = getFirstSiblingContent(siblings);
            },
            mitigation: function (el: Element) {
                parsed.mitigation = el;
            },
            'public-info': function (el: Element) {
                const f = (el: Element) => (idx: number) => {
                    const elem = el.querySelector(`:scope > li:nth-of-type(${idx})`);
                    const text = elem?.textContent || '';
                    const fallback = (text.match(/\[.+?\]/g) || []).map((s) => s.slice(1, -1)).join(' ');
                    return { text, fallback, elem };
                };

                const poc = f(el)(1);
                const exploit = f(el)(2);

                parsed.public_info_poc =
                    poc.text.indexOf('Yes') >= 0
                        ? 'Available'
                        : poc.text.indexOf('No') >= 0
                        ? 'Not Available'
                        : poc.fallback;

                parsed.public_info_exploit =
                    exploit.text.indexOf('Yes') >= 0
                        ? 'Available'
                        : exploit.text.indexOf('No') >= 0
                        ? 'Not Available'
                        : exploit.fallback;

                parsed.public_info_exploit_element = el.querySelector(`:scope > li:nth-of-type(2) > ul:nth-of-type(1)`);

                parsed.public_info_rest_element = el.cloneNode(true) as Element;

                const g = f(parsed.public_info_rest_element);

                [1, 2].map((i) => g(i).elem).forEach((e) => e?.remove());
            },
            platforms: function (el: Element) {
                parsed.platforms = el.querySelector(`:scope > ul`);
            },
            versions: function (el: Element) {
                parsed.versions = el.querySelector(`:scope > ul`);
            },
        },
        urlhook(attr: any) {
            options.urlhook && options.urlhook(parsed, attr);
        },
    });
    return Object.assign({}, result, { parsed });
}

export { parse, template };
