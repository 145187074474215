import type { AxiosInstance } from 'axios';
import storejs from 'storejs';

class Auth {
    private http: AxiosInstance;

    constructor(http: AxiosInstance) {
        this.http = http;
    }

    login = (data: { username: string; password: string }) => {
        return this.http
            .post('/auth/login', data)
            .then((response) => {
                this.setToken(response.data.token, response.data.user.id);
                return Promise.resolve({ success: true, data: response.data });
            })
            .catch(function (error) {
                throw error;
            });
    };

    logout = () => {
        this.setToken('', '');
    };

    refresh = () => {
        return this.http
            .post('/auth/refresh')
            .then((response) => {
                this.setToken(response.data.token, response.data.user.id);
                return Promise.resolve({ success: true, data: response.data });
            })
            .catch((error) => {
                throw error;
            });
    };

    setHeader = (token: string) => {
        if (token) {
            this.http.defaults.headers.common['Authorization'] = `Token ${token}`;
        } else {
            delete this.http.defaults.headers.common['Authorization'];
        }
    };

    setToken = (token: string, id: string) => {
        this.setHeader(token);
        storejs.set('token', {
            token: token,
            id: id,
        });
    };
}

export default Auth;
