import { RouteRecordRaw } from 'vue-router';
import AuthIndex from '@/auth/AuthIndex.vue';
import AuthLoginView from '@/auth/views/AuthLoginView.vue';
import AuthPasswordResetView from './views/AuthPasswordResetView.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/auth',
        name: 'auth',
        component: AuthIndex,
        children: [
            {
                path: 'login',
                component: AuthLoginView,
                name: 'login',
                meta: {
                    cover: true,
                    title: 'Login',
                },
            },
            {
                path: 'reset',
                component: AuthPasswordResetView,
                name: 'password-reset',
                meta: {
                    title: 'Password Reset',
                },
            },
            {
                path: 'reset/:token',
                component: AuthPasswordResetView,
                name: 'password-reset-token',
                meta: {
                    title: 'Password Reset',
                },
                props: true,
            },
            {
                path: 'activate/:token',
                component: AuthPasswordResetView,
                name: 'activate',
                meta: {
                    title: 'Activation',
                },
                props: true,
            },
        ],
    },
];

export default routes;
