import { useApiMutate } from '@/composables/useApiMutate';
import { AttachmentModifiable } from '@/models';
import { API_ENDPOINT, ATTACHMENT_TYPE } from '@/types';
import { API_REQUEST_TYPE } from '@/composables/useApiRequest';
import { computed } from 'vue';

export const useAttachmentUpload = (fm_id: string) => (type: ATTACHMENT_TYPE) => {
    const { mutate, state, model, error } = useApiMutate<AttachmentModifiable>({
        endpoint: API_ENDPOINT.INTERNAL_ATTACHMENT,
        type: API_REQUEST_TYPE.CREATE,
        options: {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    })({
        id: `attachment-create-attachment-${fm_id}-${type}`,
        model: {
            filename: '',
            fm_id: fm_id,
            content: new File([], ''),
            type: type,
        },
        error: {},
    });

    const creating = computed<boolean>(() => state.value === 'loading');

    const onCreateHandler = (e: InputEvent) => {
        const target = e.target as HTMLInputElement;

        if (target.files && target.files.length > 0) {
            const f = target.files[0];
            model.value.content = f;
            model.value.filename = f.name;

            mutate();
        }
    };

    return { creating, mutate, state, model, error, onCreateHandler };
};
