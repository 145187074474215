import { RouteRecordRaw } from 'vue-router';
import ProfileIndex from '@/profile/ProfileIndex.vue';
import ProfileView from '@/profile/views/ProfileView.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/profile',
        name: 'profile',
        component: ProfileIndex,
        meta: {
            title: 'Profile',
        },
        children: [
            {
                path: '',
                component: ProfileView,
                name: 'profile-index',
            },
        ],
    },
];

export default routes;
