import { ref } from 'vue';

export const useToggle = () => {
    const toggled = ref(false);

    const toggle = (t: boolean) => {
        (() => (toggled.value = t))();
    };

    return { toggled, toggle };
};
