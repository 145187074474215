import { API_ENDPOINT } from '@/types';
import { AxiosRequestConfig } from 'axios';
import { API_REQUEST_TYPE, useApiRequest } from '@/composables/useApiRequest';
import { useInputStoreFactory } from '@/composables/useInputStoreFactory';
import { watch } from 'vue';
import { storeToRefs } from 'pinia';
import { Modifiable } from '@/models';

export interface ApiParams {
    endpoint: API_ENDPOINT;
    type: API_REQUEST_TYPE;
    id?: string;
    options?: AxiosRequestConfig;
}

export interface StoreParams<T, E> {
    id: string;
    model: T;
    error: E;
}

export interface ApiDefaultError {
    non_field_errors?: string[];
}

export type ApiMutateError<T> = {
    [key in keyof T]?: string[];
};

export type ApiError<T> = ApiDefaultError & ApiMutateError<T>;

export const useApiMutate =
    <T extends Modifiable, E extends ApiDefaultError = ApiError<T>>(apiParams: ApiParams) =>
    (storeParams: StoreParams<T, E>) => {
        const { useStore } = useInputStoreFactory<T, E>(storeParams.id)(storeParams.model)(storeParams.error);

        const store = useStore();

        store.$patch({
            model: storeParams.model,
            error: storeParams.error,
        });

        const { model, error } = storeToRefs(store);

        const api = useApiRequest<T, E>(apiParams.endpoint)(apiParams.type)(apiParams.id)(model)(apiParams.options);

        watch(api.state, () => {
            if (api.isError(api.result)) {
                store.$patch({
                    error: api.result.error.value ?? {
                        non_field_errors: [api.result.rawError.value.message ?? ''],
                    },
                });
            } else {
                store.$patch({
                    error: storeParams.error,
                });
            }
        });

        return {
            mutate: api.fetch,
            state: api.state,
            model,
            error,
        };
    };
