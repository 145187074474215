import { computed, Ref, watch } from 'vue';
import { ApiDefaultError, ApiError } from '@/composables/useApiMutate';
import { useToggle } from '@/composables/useToggle';

export const useApiMutateErrorModal =
    <T, E extends ApiDefaultError = ApiError<T>>(k: Array<keyof E> = ['non_field_errors']) =>
    (error: Ref<E>) => {
        const { toggle, toggled } = useToggle();

        const errorFiltered = computed(() =>
            Object.fromEntries(Object.entries(error.value).filter(([key]) => k.includes(key as keyof E)))
        );

        watch(errorFiltered, () => {
            if (Object.keys(errorFiltered.value).length > 0) {
                toggle(true);
            }
        });

        return { toggle, toggled, error: errorFiltered };
    };
