import type { RouteRecordRaw } from 'vue-router';
import DashboardIndex from '@/dashboard/DashboardIndex.vue';
import DashboardView from '@/dashboard/views/DashboardView.vue';

const routes: RouteRecordRaw[] = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardIndex,
        meta: {
            title: 'Dashboard',
        },
        children: [
            {
                path: '',
                component: DashboardView,
                name: 'dashboard-index',
            },
        ],
    },
];

export default routes;
