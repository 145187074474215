<script setup lang="ts"></script>

<template>
    <footer>Fermium &copy; 2022</footer>
</template>

<style scoped lang="scss">
@import '~@/styles/_variables.scss';
@import '~@/styles/_mixins.scss';

footer {
    font-size: $text-size-m;
    color: $text-color-primary;
    width: 100%;
    text-align: center;
    margin: 1.375rem 0;
}
</style>
