export type IMPACT_TYPE_INDEX = '1' | '2' | '3' | '-1';

export const IMPACT_TYPE: Record<IMPACT_TYPE_INDEX, string> = {
    1: 'Elevation of Privilege (EoP)',
    2: 'Remote Code Execution (RCE)',
    3: 'Information Disclosure',
    '-1': 'Invalid Impact Type',
};

export enum ATTACHMENT_TYPE {
    NONE,
    POC,
    EXP,
}

export enum API_ENDPOINT {
    DASHBOARD = '/dashboard',
    REPORT = '/report',
    ADMIN_USER = '/admin/user',
    COMPANY = '/company',
    USER = '/user',
    AUTH_UPDATE = '/auth/update',
    AUTH_RESET = '/auth/reset',
    INTERNAL_ATTACHMENT = '/internal/attachment',
    INTERNAL_REPORT = '/internal/report',
}
